import {
  isOneTrustCategoryConsented,
  OneTrustCategory,
  setSessionStorageItem,
  getSessionStorageItem,
  nowAndOnOneTrustConsentChanged,
  onOneTrustConsentChanged,
  getOneTrustActiveGroups,
  isOptOutConsentModel,
  isOptOutRegion,
} from '@square/onetrust-compliant-access';
import { CDP } from '@squareup/cdp';
import {
  getNextPublicCdpKey,
  isServerContext,
} from '@squareup/dex-utils-environment';
import EventstreamClient from 'eventstream.js';
import { gtag, install } from 'ga-gtag';
import throttle from 'lodash/fp/throttle';

import {
  WebAppEventClientOptions,
  TrackPageNavigationMetadata,
  TrackPageActionMetadata,
} from './types';

const getCdpLinkClickEventProperties = (event: MouseEvent) => {
  const target = event.target as Element | null;
  if (!target) {
    return {};
  }

  const link = target.hasAttribute('href') ? target : target.closest('[href]');
  if (!link) {
    return {};
  }

  const href = link.getAttribute('href');
  const linkUrl = href?.startsWith('http')
    ? href
    : `${window.location.origin}${href || ''}`;
  const linkId = link.getAttribute('id');
  const parentNode = link.parentNode as Element | null;
  const linkParentId = parentNode?.closest('[id]')?.getAttribute('id') || null;
  const linkText = link.textContent?.trim().replace(/\s\s+/g, ' ') || null;
  const linkTitle = link.getAttribute('title');

  return {
    linkId,
    linkParentId,
    linkText,
    linkTitle,
    linkUrl,
  };
};

const withoutTopLevelUndefinedEntries = (obj: object) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );

export class WebAppEventClient {
  private cdpClient?: CDP;
  private eventstreamClient?: EventstreamClient;

  private config: WebAppEventClientOptions;

  private debugMode = false;
  private clientSessionId = '';
  private clientSessionStartTime = -1;
  private pageLoadSessionId = '';
  private pageLoadTime = -1;
  private currentPageName = '';
  private currentPageId = '';
  private prevPageName = '';
  private prevPageId = '';
  private lastNavTime = -1;

  // Google Analytics configuration
  private gTagEnabled = false;
  private gaTrackingId?: string;
  private gTagInitialized = false;

  // CDP heartbeat
  private heartbeatInterval: number | undefined;
  private heartbeatIntervalMs = 5000;
  private waitSetUserActive = 1000;
  private userActive = true;
  private heartbeatStarted = false;

  constructor(config: WebAppEventClientOptions) {
    if (isServerContext()) {
      throw new Error('WebAppEventClient cannot be used in server context');
    }

    this.config = config;

    const { applicationName, environment, gtag } = config;

    if (gtag) {
      this.gTagEnabled = gtag.enable;
      this.gaTrackingId = gtag.gaTrackingId;
    }

    this.clientSessionId = this.getClientSessionId();
    this.clientSessionStartTime = this.getClientSessionStartTime();
    this.pageLoadSessionId = crypto.randomUUID();
    this.pageLoadTime = this.getNavigationStartTime();
    this.currentPageName = '';
    this.currentPageId = '';
    this.prevPageName = '';
    this.prevPageId = '';
    this.lastNavTime = this.pageLoadTime;

    if (environment === 'production' || environment === 'staging') {
      this.eventstreamClient = new EventstreamClient({
        applicationName,
        environment,
      });

      // Initialize GA when consent is given
      if (this.gTagEnabled) {
        nowAndOnOneTrustConsentChanged(() => {
          if (isOneTrustCategoryConsented(OneTrustCategory.PERFORMANCE)) {
            this.initializeGoogleAnalytics();
          }
        });
      }

      onOneTrustConsentChanged(() => {
        this.trackOneTrustConsentChanged();
      });

      this.cdpClient = new CDP({
        apiKey: getNextPublicCdpKey(),
        application: {
          name: applicationName,
        },
        environment,
      });
    }

    if (this.config.enableHeartbeat && !this.heartbeatStarted) {
      this.startHeartbeat();
    }

    if (this.config.enableLinkClickListener) {
      this.setupLinkClickListener();
    }
  }

  private initializeGoogleAnalytics() {
    if (this.gTagInitialized) {
      return;
    }

    if (this.gTagEnabled && this.gaTrackingId) {
      install(this.gaTrackingId);
      gtag('config', this.gaTrackingId, {
        send_page_view: false,
        custom_map: { dimension1: 'mode' },
      });

      this.gTagInitialized = true;
    }
  }

  get esCdpConsented() {
    return (
      isOneTrustCategoryConsented(OneTrustCategory.TARGETING) ||
      this.isOneTrustOptOut()
    );
  }

  get gTagAvailable() {
    return (
      this.gTagInitialized &&
      isOneTrustCategoryConsented(OneTrustCategory.PERFORMANCE)
    );
  }

  public trackPageNavigation({
    name,
    type,
    details,
    loadStatus,
    mode,
    callback,
  }: TrackPageNavigationMetadata) {
    this.prevPageName = this.currentPageName;
    this.prevPageId = this.currentPageId;
    this.currentPageName = name || window.document.location.pathname;
    const currentTime = this.getCurrentTime();
    const durationSincePreviousNavigation = currentTime - this.lastNavTime;
    this.lastNavTime = currentTime;
    this.currentPageId = `${this.pageLoadSessionId}_${currentTime}`;

    const eventName = 'devs_page_nav';

    const payload = {
      catalog_name: eventName,
      devs_page_nav_type: type,
      devs_page_nav_current_page_name: this.currentPageName,
      devs_page_nav_current_page_id: this.currentPageId,
      devs_page_nav_previous_page_name: this.prevPageName,
      devs_page_nav_previous_page_id: this.prevPageId,
      devs_page_nav_current_time: currentTime,
      devs_page_nav_duration_since_previous_nav:
        durationSincePreviousNavigation,
      devs_page_nav_load_status: loadStatus,
      devs_page_nav_details: details,
      devs_page_nav_mode: mode,
      ...this.getSessionMetadata(eventName),
    };

    if (this.esCdpConsented) {
      const es2Payload = {
        ...payload,
        callback,
      };
      if (this.debugMode) {
        this.debugLog('eventstream.trackV2WithDefaults()', es2Payload);
      } else {
        this.eventstreamClient?.trackV2WithDefaults(es2Payload);
      }
    }

    if (this.esCdpConsented) {
      if (this.debugMode) {
        this.debugLog('cdp.trackStrict()', payload);
      } else {
        this.cdpClient?.trackStrict({
          eventName,
          eventProps: payload,
        });
      }
    }

    if (this.gTagAvailable && this.gaTrackingId && type !== 'leave') {
      gtag('config', this.gaTrackingId, {
        page_title: window.document.title,
        page_path: window.location.pathname,
        page_location: window.location.href,
      });
    }

    if (this.heartbeatStarted && type !== 'leave') {
      this.handleHeartbeatOnRouteChange();
    }
  }

  public trackPageAction(
    name: string,
    {
      category,
      label,
      value,
      mode,
      nonInteraction,
      userRole,
      callback,
    }: TrackPageActionMetadata,
    bypassConsent = false
  ) {
    const eventName = 'devs_page_action';

    const payload = {
      catalog_name: eventName,
      devs_page_action_name: name,
      devs_page_action_category: category,
      devs_page_action_label: label,
      devs_page_action_value: value,
      devs_page_action_mode: mode,
      devs_page_action_current_time: this.getCurrentTime(),
      devs_page_action_non_interaction: nonInteraction || false,
      devs_page_action_current_page_name: this.currentPageName,
      devs_page_action_current_page_id: this.currentPageId,
      element_identifier: userRole,
      ...this.getSessionMetadata(eventName),
    };

    if (this.esCdpConsented || bypassConsent) {
      const es2Payload = {
        ...payload,
        callback,
      };
      if (this.debugMode) {
        this.debugLog('eventstream.trackV2WithDefaults()', es2Payload);
      } else {
        this.eventstreamClient?.trackV2WithDefaults(es2Payload);
      }
    }

    if (this.esCdpConsented || bypassConsent) {
      if (this.debugMode) {
        this.debugLog('cdp.trackStrict()', payload);
      } else {
        this.cdpClient?.trackStrict({
          eventName,
          eventProps: payload,
        });
      }
    }

    if (this.gTagAvailable) {
      gtag('event', name, {
        event_category: category,
        event_label: label,
        value,
        mode,
      });
    }
  }

  public trackOneTrustConsentChanged() {
    // Getting the raw OneTrust global active groups for telemetry
    // See: https://github.com/squareup/onetrust-compliant-access/blob/master/src/onetrust-handler.ts#L30
    const label = getOneTrustActiveGroups();
    return this.trackPageAction('Onetrust Consent Changed', { label }, true);
  }

  public setupLinkClickListener() {
    document.addEventListener('click', this.handleLinkClick, { passive: true });
  }

  /**
   * Reports a 'devs_page_link_click' event to CDP. This method can be called
   * through link components' click handlers. Alternatively, it can be called
   * automatically on link click events through a global event listener by
   * setting `enableLinkClickListener` to `true`.
   */
  public trackLinkClick(event: MouseEvent) {
    if (!this.esCdpConsented) {
      return;
    }

    const eventName = 'devs_page_link_click';

    const payload = withoutTopLevelUndefinedEntries({
      ...getCdpLinkClickEventProperties(event),
      ...this.getSessionMetadata(eventName),
    });

    if (this.debugMode) {
      this.debugLog('cdp.trackStrict()', payload);
    } else {
      this.cdpClient?.trackStrict({ eventName, eventProps: payload });
    }
  }

  public removeLinkClickListener() {
    document.removeEventListener('click', this.handleLinkClick);
  }

  private getClientSessionId() {
    const storedValue = getSessionStorageItem('client_session_id');

    if (!storedValue) {
      const value = crypto.randomUUID();

      setSessionStorageItem(
        'client_session_id',
        value,
        OneTrustCategory.TARGETING
      );

      return value;
    }

    return `${storedValue}`;
  }

  private getClientSessionStartTime() {
    const storedValue = getSessionStorageItem('client_session_start_time');

    if (!storedValue) {
      const value = this.getNavigationStartTime();

      setSessionStorageItem(
        'client_session_start_time',
        `${value}`,
        OneTrustCategory.TARGETING
      );

      return value;
    }

    return Number(storedValue);
  }

  private getSessionMetadata(prefix: string) {
    return {
      [`${prefix}_client_session_id`]: this.clientSessionId,
      [`${prefix}_client_session_start_time`]: this.clientSessionStartTime,
      [`${prefix}_client_session_new_load_id`]: this.pageLoadSessionId,
      [`${prefix}_client_session_new_load_time`]: this.pageLoadTime,
    };
  }

  private getCurrentTime() {
    return this.pageLoadTime + Math.trunc(window.performance.now());
  }

  private getNavigationStartTime() {
    return Math.floor(window.performance.timeOrigin);
  }

  private isOneTrustOptOut() {
    return isOptOutRegion() || isOptOutConsentModel();
  }

  private debugLog(...args: unknown[]) {
    // eslint-disable-next-line no-console
    return console.log(...args);
  }

  private startHeartbeat() {
    this.heartbeatStarted = true;

    const userActiveEvents = [
      'load',
      'click',
      'keypress',
      'touchstart',
      'mousemove',
      'mousedown',
      'scroll',
    ];

    const throttledSetActive = throttle(
      this.waitSetUserActive,
      this.setUserActive
    ).bind(this);

    // listen for all events and set user as active
    for (const event of userActiveEvents) {
      document.addEventListener(event, throttledSetActive, { passive: true });
    }

    this.restartHeartbeatInterval();
  }

  private setUserActive() {
    this.userActive = true;
  }

  private handleHeartbeatOnRouteChange() {
    this.setUserActive();
    this.trackHeartbeat();
    this.restartHeartbeatInterval();
  }

  private restartHeartbeatInterval() {
    clearInterval(this.heartbeatInterval);
    this.heartbeatInterval = window.setInterval(
      this.trackHeartbeat.bind(this),
      this.heartbeatIntervalMs
    );
  }

  private trackHeartbeat() {
    if (this.userActive) {
      // Set it to false every heartbeat, since we only want
      // to send it if there's been user interaction since the last heartbeat
      this.userActive = false;
      this.trackPageAction('heartbeat', { value: -1 });
    }
  }

  private handleLinkClick = (event: MouseEvent) => {
    const linkSelector = 'a[href]:not([href=""])';
    const linkAncestor = (event.target as Element).closest(linkSelector);

    /**
     * This handler is meant to be bound to document, so only act on events
     * with a link ancestor
     */
    if (!linkAncestor) {
      return;
    }

    this.trackLinkClick(event);
  };
}
