import {
  CanonicalRole,
  PermissionSetDomainAction,
} from '@squareup/dex-types-shared-rbac-permissions';

const ACTION_TO_CANONICAL_ROLE_MAPPINGS = {
  [PermissionSetDomainAction.VIEW_APPLICATIONS]: [
    CanonicalRole.DEVELOPER_APPLICATION_READ,
  ],

  [PermissionSetDomainAction.UPDATE_APPLICATIONS]: [
    CanonicalRole.DEVELOPER_APPLICATION_WRITE,
  ],

  [PermissionSetDomainAction.VIEW_APPLICATION_CREDENTIALS]: [
    CanonicalRole.DEVELOPER_APPLICATION_CREDENTIALS_READ,
    CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_READ,
    CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_WRITE,
    CanonicalRole.DEVELOPER_APPLICATION_OAUTH_READ,
    CanonicalRole.DEVELOPER_APPLICATION_READER_SDK_READ,
    CanonicalRole.DEVELOPER_APPLICATION_POINT_OF_SALE_API_READ,
    CanonicalRole.DEVELOPER_APPLICATION_APPLE_PAY_READ,
  ],

  [PermissionSetDomainAction.UPDATE_APPLICATION_CREDENTIALS]: [
    CanonicalRole.DEVELOPER_APPLICATION_CREDENTIALS_WRITE,
    CanonicalRole.DEVELOPER_APPLICATION_OAUTH_WRITE,
    CanonicalRole.DEVELOPER_APPLICATION_READER_SDK_WRITE,
    CanonicalRole.DEVELOPER_APPLICATION_POINT_OF_SALE_API_WRITE,
    CanonicalRole.DEVELOPER_APPLICATION_APPLE_PAY_WRITE,
  ],

  [PermissionSetDomainAction.VIEW_API_LOGS]: [CanonicalRole.API_LOGS_READ],

  [PermissionSetDomainAction.VIEW_SANDBOX_ENVIRONMENT_DETAILS]: [
    CanonicalRole.DEVELOPER_SANDBOX_ACCOUNT_READ,
  ],

  [PermissionSetDomainAction.UPDATE_SANDBOX_ENVIRONMENT_DETAILS]: [
    CanonicalRole.DEVELOPER_SANDBOX_ACCOUNT_WRITE,
  ],

  [PermissionSetDomainAction.UPDATE_APP_MARKETPLACE_REVIEWS]: [
    CanonicalRole.DEVELOPER_APPLICATION_REVIEWS_WRITE,
  ],

  [PermissionSetDomainAction.VIEW_APP_MARKETPLACE_SUBMISSIONS]: [
    CanonicalRole.DEVELOPER_APPLICATION_SUBMISSIONS_READ,
  ],

  [PermissionSetDomainAction.UPDATE_APP_MARKETPLACE_SUBMISSIONS]: [
    CanonicalRole.DEVELOPER_APPLICATION_SUBMISSIONS_WRITE,
  ],

  [PermissionSetDomainAction.UPDATE_DEVELOPER_ACCOUNT_SETTINGS]: [
    CanonicalRole.DEVELOPER_PROFILE_WRITE,
  ],

  [PermissionSetDomainAction.UPDATE_DEVELOPER_ACCOUNT_PERMISSIONS]: [
    CanonicalRole.DEVELOPER_COLLABORATOR_PERMISSIONS_WRITE,
  ],

  [PermissionSetDomainAction.UPDATE_APPLICATION_OAUTH]: [
    CanonicalRole.DEVELOPER_APPLICATION_OAUTH_WRITE,
  ],

  [PermissionSetDomainAction.MANAGE_EMPLOYEES]: [
    CanonicalRole.EMPLOYEE_DASHBOARD_MANAGE_EMPLOYEES,
  ],

  [PermissionSetDomainAction.UPDATE_APPLICATION_SIGNATURES]: [
    CanonicalRole.DEVELOPER_APPLICATION_APP_SIGNATURES_WRITE,
  ],

  [PermissionSetDomainAction.UPDATE_APPLICATION_WEBHOOKS]: [
    CanonicalRole.DEVELOPER_APPLICATION_WEBHOOKS_WRITE,
  ],
};

const getRolesForDomainAction = (
  permissionSetDomainAction: PermissionSetDomainAction
): CanonicalRole[] =>
  ACTION_TO_CANONICAL_ROLE_MAPPINGS[permissionSetDomainAction];

export { getRolesForDomainAction };
