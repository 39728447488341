enum DeveloperApiTagTypes {
  Flag = 'Flag',
  Specification = 'Specification',
  SmileySurveySettings = 'SmileySurveySettings',
  ApplicationDetail = 'ApplicationDetail',
  OAuthClient = 'OAuthClient',
  OAuthApplications = 'OAuthApplications',
  AccessToken = 'AccessToken',
  ApiVersions = 'ApiVersions',
  PortalUser = 'PortalUser',
  ApiKey = 'ApiKey',
  SandboxTestAccount = 'SandboxTestAccount',
  AppSubmission = 'AppSubmission',
  QaAppSubmission = 'QaAppSubmission',
  ReviewerFeedback = 'ReviewerFeedback',
  ToS = 'ToS',
  SandboxAuthorization = 'SandboxAuthorization',
  DeveloperContacts = 'DeveloperContacts',
  RbacPermissions = 'RbacPermissions',
  AppSignature = 'AppSignature',
  WebhooksSubscriptions = 'WebhooksSubscriptions',
  WebhookEventTypes = 'WebhookEventTypes',

  // Preferences
  PreferenceScope = 'PreferenceScope',
  PreferenceGroup = 'PreferenceGroup',
}

export { DeveloperApiTagTypes };
