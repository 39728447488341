const NewWebAppEventClientEnabledLocalStorageKey =
  'new_web_app_event_client_enabled';

const isNewWebAppEventClientEnabled = (): boolean => {
  try {
    const value = localStorage.getItem(
      NewWebAppEventClientEnabledLocalStorageKey
    );

    if (value === 'true') {
      return true;
    }
  } catch {
    return false;
  }

  return false;
};

export {
  isNewWebAppEventClientEnabled,
  NewWebAppEventClientEnabledLocalStorageKey,
};
